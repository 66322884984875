import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import {
  Autoplay,
  EffectFade,
  Keyboard,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  Swiper as SwiperClass
} from 'swiper'

SwiperClass.use([Pagination, Autoplay, Navigation, Lazy, Scrollbar, Keyboard, EffectFade])

const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

export default {
  components: {Swiper, SwiperSlide}
}
